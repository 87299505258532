<template>
    <setting-layout title="Withdraw">
        <v-container>
            <v-row justify="center">
                <div class="hint text-center">{{ $t('pages.profile.wallet.withdraw.available_balance') }}</div>
            </v-row>
            <v-row justify="center">
                <div class="hint text-center available">{{ currency }}{{ user.balance }}</div>
            </v-row>
            <v-row justify="center">
                <div class="big-text d-flex justify-center align-center text-center">
                    <span>{{ currency }}</span>
                    <v-text-field
                        class="total"
                        dense
                        solo
                        type="number"
                        v-model.number="total_display"
                        :rules="rules"
                        :min="0"
                        :max="1000000"
                        :error="errors.hasOwnProperty('amount')"
                        :error-messages="errors.hasOwnProperty('amount') ? errors.amount[0] : ''"
                    >
                    </v-text-field>
                </div>
            </v-row>
            <v-row>
                <v-col class=" bigger-font">
                    {{ $t('pages.profile.wallet.withdraw.total_gross') }}
                </v-col>
                <v-col class="text-end bigger-font">
                    {{ total_gross }}{{ currency }}
                </v-col>
            </v-row>
            <v-row>
                <v-col class="d-flex align-center bigger-font">
                    {{ $t('pages.profile.wallet.withdraw.total_net') }} 
                    <icon-info class="ml-1 mt-1" @click="showTagsInfo = !showTagsInfo" />
                </v-col>
                <v-col class="text-end bigger-font">
                    {{ total_net }}{{ currency }}
                </v-col>
            </v-row>
            <v-row v-if="showTagsInfo">
                <div class="align-center ml-4">
                    {{ $t('pages.profile.wallet.withdraw.total_net_info') }}
                </div>
            </v-row>
            <v-row>
                <v-col>
                    <v-select
                        background-color="rgba(237,237,237, .1)"
                        color="orange"
                        :items="items"
                        label="Solo field"
                        solo
                        flat
                        item-color="orange"
                        item-text="text"
                        item-value="val"
                        v-model="paymentSystem"
                        :error="errors.hasOwnProperty('paymentSystem')"
                        :error-messages="errors.hasOwnProperty('paymentSystem') ? errors.paymentSystem[0] : ''"
                    ></v-select>
                </v-col>
            </v-row>
            <v-row class="pl-4 pr-4" justify="center">
                <gradient-button block @click="save"
                                 :disable="!canWithdrawn"
                                 :loading="$store.getters.loadingBtn(['setting', 'walletWithdrawBtn'])">WITHDRAW
                </gradient-button>
            </v-row>
        </v-container>
        <inform-dialog
            :text="infoDialog.msg"
            v-if="infoDialog.isShow"
            @close="infoDialog.isShow=false"
        />
        <verify-dialog
            v-if="isShowVerifyDialog"
            @close="isShowVerifyDialog=false"
        />
    </setting-layout>
</template>

<script>
import SettingLayout from "@/layouts/app/profile/SettingLayout";
import InformDialog from "@/components/app/dialogs/InformDialog";
import GradientButton from "@/components/app/button/GradientButton";
import {CURRENCY_SYMBOL} from "@/configs/constants";
import {mapActions, mapMutations, mapState} from "vuex";
import {DEFAULT_FEES} from "@/configs/constants";
import isNumber from "lodash/isNumber";
import Withdrawal from "@/models/Withdrawal";
import VerifyDialog from "@/components/app/dialogs/VerifyDialog.vue";
import IconInfo from "@/assets/images/icons/info.svg?inline";

export default {
    name: "Withdraw",
    data: () => ({
        paymentSystem: null,
        total: 0,
        currency: CURRENCY_SYMBOL,
        errors: {},
        isShowVerifyDialog: false,
        infoDialog: {
            isShow: false,
            msg: '',
        },
        showTagsInfo: false,
    }),
    computed: {
        ...mapState({
            user: state => state.user.user.data,
        }),
        canWithdrawn() {
            return this.total > 0
        },
        rules() {
            return [
                v => isNumber(v) || `The amount must be a number.`,
                v => v > 0 || `Amount should be above ${CURRENCY_SYMBOL}0`,
                v => v <= 100000 || `Max should not be above ${CURRENCY_SYMBOL}100000`,
                v => v <= this.user.balance || `Your balance is insufficient`,
            ]
        },
        total_display: {
            get: function () {
                return this.total
            },
            set: function (newValue) {
                if (isNumber(newValue)) {
                    this.total = newValue
                }
            }
        },
        total_gross() {
            return Number(this.total).toFixed(2)
        },
        total_net() {
            return Number(this.total_gross - this.total_gross * (DEFAULT_FEES / 100)).toFixed(2)
        },
        items() {
            return [
                {text: this.$t('pages.profile.wallet.withdraw.bank_transfer'), val: 'bank'},
                {text: this.$t('pages.profile.wallet.withdraw.bitsafe'), val: 'bitsafe'},
            ]
        }
    },
    methods: {
        ...mapMutations([
            'showSnackBar',
        ]),
        ...mapActions([
            'getBitsafe',
            'getBankTransfer',
            'getCurrentUserData',
        ]),
        save() {
            this.errors = {};
            if (!(this.user.balance > 0)) {
                this.infoDialog.msg = 'Your balance is insufficient';
                this.infoDialog.isShow = true;
                return;
            }

            if (this.paymentSystem === 'bank' && this.total_gross < 100) {
                this.infoDialog.msg = this.$t('dialogs.withdrawal_warning.bank_transfer.description');
                this.infoDialog.isShow = true;
                return;
            }
            if (this.paymentSystem === 'bitsafe' && this.total_gross < 50) {
                this.infoDialog.msg = this.$t('dialogs.withdrawal_warning.bitsafe.description');
                this.infoDialog.isShow = true;
                return
            }

            new Withdrawal({
                paymentSystem: this.paymentSystem,
                amount: this.total_gross,
            }).save().then(() => {
                this.getCurrentUserData();
                this.errors = {}
                this.showSnackBar(this.$t('messages.success'))
                this.$router.push({name: 'user.own.profile.wallet'})
            }).catch(({response: res}) => {
                if (res.status === 400) {
                    if (res.data.err_key === 'unverified_account') {
                        this.isShowVerifyDialog = true
                        return
                    }
                }
                if (res.status === 422) {
                    this.errors = res.data.errors
                    return
                }
                this.showSnackBar(this.$t('messages.something_went_wrong'))
            })
        }
    },
    components: {
        VerifyDialog,
        InformDialog,
        GradientButton,
        SettingLayout,
        IconInfo
    },
    mounted() {
        if (this.items.length) {
            this.paymentSystem = this.items[0].val
        }
    },
    created() {
        this.getBitsafe()
        this.getBankTransfer()
    }
}
</script>

<style scoped lang="scss">
@import "@/sass/_mixins";

.total {
    font-size: 3.6rem;
    max-width: 200px;
    position: relative;
}

.hint {
    color: rgba(255, 255, 255, 0.6);
}

.available {
    font-size: 1.2em;
}

.big-text {
    font-size: 3.6em;
}

.bigger-font {
    font-size: 1.2em;
    line-height: 1em;
}

.small-text {
    font-size: 0.7em;
    margin-top: -18px;
}
</style>

<style>
.total.v-text-field.v-text-field--enclosed .v-text-field__details {
    position: absolute;
    bottom: -16px;
    left: 0;
    width: 100%;
}

.total.v-text-field.v-text-field--solo.v-input--dense > .v-input__control {
    align-items: center;
    padding: 0;
    margin: 0;
}

.total.v-text-field.v-text-field--enclosed:not(.v-text-field--rounded) > .v-input__control > .v-input__slot {
    align-items: center;
    padding: 0;
    margin: 0;
}

.total.v-text-field.v-text-field--solo .v-input__control input {
    max-height: inherit !important;
}
</style>
